import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import Header from './components/header';
import AboutUs from './components/aboutUs';
import Awarded from './components/awarded';
import Overview from './components/overview';
import Portfolio from './components/portfolio';
import Ranking from './components/ranking';
import CMSContact from '../../cms-modules/contact';
import CMSEventTeaser from '../../cms-modules/eventTeaser';
import { MicrocopyContext } from '../../../context/microcopy.context';
import { getCopy } from '../../../helpers/copy';
import { isFuture } from 'date-fns';
import {
  API_ROUTES,
  getCountryName,
  STATIC_ROUTES,
} from '../../../utils/constants';
import { combine } from '../../../helpers/styles';
import * as styles from './styles.module.scss';
import DetailNavigation from '../../ui/detailPageNavigation';
import { getAPIRoot } from '../../../helpers/fetch';
import Container from '../../../modules/container';
import { useLocale } from '../../../context/locale.context';

const CompanyDetail = ({ data, isPartial }) => {
  const microcopy = useContext(MicrocopyContext);
  const [detailNavigation, setDetailNavigation] = useState(null);
  const { locale } = useLocale();

  const getDetailNavigation = async () => {
    const payloadAsString = sessionStorage?.getItem('profilePayload');
    const count = sessionStorage?.getItem('profileCount') ?? 0;
    if (payloadAsString) {
      const payload = JSON.parse(payloadAsString);
      const apiRoot = await getAPIRoot();
      const res = await fetch(
        `${apiRoot}${API_ROUTES.COMPANIES_NAVIGATION}/${data.id}/${payload.page}`,
        {
          method: 'POST',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
          },
          body: payloadAsString,
        }
      );
      const json = await res.json();
      setDetailNavigation({
        count,
        ...json,
      });
    }
  };

  useEffect(() => {
    getDetailNavigation();

    return () => {
      sessionStorage?.removeItem('profileCount');
      sessionStorage?.removeItem('profilePayload');
      sessionStorage?.removeItem('profileQueryParams');
    };
  }, []);

  const markupOrEmpty = (element, markup) => (element ? markup : '');

  const getProfileTypeLabel = () =>
    getCopy(`filters.profiletypes.${data.profileType}.profile`, microcopy);

  const primaryContactInfo = {
    organization: data.organization,
    headline: data.headline,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    salutation: data.salutation,
    forename: data.forename,
    surname: data.surname,
    postCode: data.postCode,
    city: data.city,
    country: data.country,
    phone: data.phone,
    email: data.email,
    website: data.website,
  };

  const secondaryContactInfo = {
    organization: data.secondOrganization,
    headline: data.headline,
    addressLine1: data.secondAddressLine1,
    addressLine2: data.secondAddressLine2,
    salutation: data.secondSalutation,
    forename: data.secondForename,
    surname: data.secondSurname,
    postCode: data.secondPostCode,
    city: data.secondCity,
    country: data.secondCountry,
    phone: data.secondPhone,
    email: data.secondEmail,
    website: data.secondWebsite,
  };

  const getContactInformation = (mapping) =>
    markupOrEmpty(mapping.organization, `<b>${mapping.organization}</b><br/>`) +
    markupOrEmpty(mapping.salutation, `${mapping.salutation} `) +
    markupOrEmpty(mapping.forename, `${mapping.forename} `) +
    markupOrEmpty(mapping.surname, `${mapping.surname}`) +
    markupOrEmpty(
      mapping.salutation || mapping.forename || mapping.surname,
      `<br/>`
    ) +
    markupOrEmpty(mapping.addressLine1, `${mapping.addressLine1}<br/>`) +
    markupOrEmpty(mapping.addressLine2, `${mapping.addressLine2}<br/>`) +
    markupOrEmpty(mapping.postCode, `${mapping.postCode} `) +
    markupOrEmpty(mapping.city, `${mapping.city}`) +
    markupOrEmpty(mapping.postCode || mapping.city, `<br/>`) +
    markupOrEmpty(
      mapping.country,
      `${getCountryName(locale, mapping.country) || ''}<br/><br/>`
    ) +
    markupOrEmpty(mapping.phone, `Phone ${mapping.phone}<br/>`) +
    markupOrEmpty(mapping.email, `${mapping.email}<br/>`) +
    markupOrEmpty(
      mapping.website,
      `<a href="${
        mapping.website?.includes('http')
          ? mapping.website
          : 'https://' + mapping.website
      }">${mapping.website}</a>`
    );

  const primaryContactMarkup = getContactInformation(primaryContactInfo);
  const secondaryContactMarkup = getContactInformation(secondaryContactInfo);

  const contactItems = [
    primaryContactMarkup && {
      headquarter: '',
      locations: '',
      founded: '',
      employees: '',
      displayAsTable: false,
      icon: 'building',
      contactInformation: primaryContactMarkup,
    },
    secondaryContactMarkup && {
      headquarter: '',
      locations: '',
      founded: '',
      employees: '',
      displayAsTable: false,
      icon: 'building',
      contactInformation: secondaryContactMarkup,
    },
    {
      headquarter: data.headquarter,
      locations: data.locations,
      founded: data.founded?.toString(),
      employees: data.employees?.toString(),
      displayAsTable: true,
      icon: '',
      contactInformation: '',
    },
  ].filter(Boolean);

  const contactSocialLinks = [
    'facebook',
    'twitter',
    'youTube',
    'pinterest',
    'instagram',
    'linkedIn',
  ]
    .filter((name) => data[name])
    .map((name) => ({
      externalLink: data[name],
      icon: name.toLowerCase(),
    }));

  const futureEvents = data.events
    ? data.events
        .filter((event) => isFuture(new Date(event.endDate)))
        .sort(
          (a, b) =>
            new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf()
        )
    : [];

  return (
    <>
      <Container
        className={combine(
          styles.detailNavigationOutercontainer,
          !detailNavigation && styles.empty
        )}
        fullWidth
      >
        <Container>
          <DetailNavigation
            navigationObject={detailNavigation}
            type="profile"
          />
        </Container>
      </Container>
      <Header
        logo={data.logo}
        anniversaryDate={data.anniversaryDate}
        anniversaryText={data.anniversaryText}
        profileType={getProfileTypeLabel()}
        sumOfProjects={data.companyProfileEntries.count}
        socialLinks={contactSocialLinks}
        hasContact={data.headquarter ? true : false}
        isCompanyDetail
        companyWebsite={data.website}
      />
      <Overview
        image={data.image}
        companyName={data.companyName}
        statement={data.statement}
        headquarter={data.headquarter}
        locations={data.locations}
        hashtags={data.hashtags}
        intro={data.introductionText}
        founded={data.founded}
        employees={data.employees}
        className={combine(
          !data.logo && !data.anniversaryText && styles.noTopPaddingOnDesktop
        )}
      />
      {!isPartial && (
        <>
          {data.companyProfileEntries &&
            data.companyProfileEntries.entries.length > 0 && (
              <Awarded
                projectItems={data.companyProfileEntries.entries}
                amountOfProjects={data.companyProfileEntries.count}
                companyName={data.companyName}
                companyId={data.id}
              />
            )}
          {data.labels && data.labels.length > 0 && (
            <Ranking rankings={data.labels} />
          )}
          {data.aboutUsTeasers && data.aboutUsTeasers.length > 0 && (
            <AboutUs items={data.aboutUsTeasers} />
          )}
          {futureEvents.length > 0 && (
            <CMSEventTeaser
              customEvents={futureEvents}
              headline={getCopy('label.eventsCalendar', microcopy)}
              text={getCopy('label.eventsCalendarText', microcopy)}
              link={{
                linkToPage: STATIC_ROUTES.EVENT_FILTER_PAGE,
                text: getCopy('label.eventsCalendarButton', microcopy),
              }}
            />
          )}
          {data.portfolios && data.portfolios.length > 0 && (
            <Portfolio items={data.portfolios} />
          )}
          <CMSContact
            headline={getCopy('label.getInContact', microcopy)}
            items={contactItems}
            socialLinks={contactSocialLinks}
          />
        </>
      )}
    </>
  );
};

CompanyDetail.propTypes = {
  data: pt.object,
  isPartial: pt.bool,
};

CompanyDetail.defaultProps = {
  data: {},
};

export default CompanyDetail;
